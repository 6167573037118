import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";

const Section2 = () => {
  return (
    <Box
      width="100%"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        px: { xs: 2, md: 0 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "20px" },
          color: "red",
        }}
      >
        Problem
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "35px", sm: "45px", md: "60px" },
          textAlign: "center",
        }}
      >
        Leads that convert are hard to find.
      </Typography>
      <Box mt={{ xs: 5, md: 10 }} width="100%" mb={{ xs: 10, md: 20 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 8, md: 15 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "30px", md: "40px" },
                color: "#FF3258",
                bgcolor: "rgba(249, 212, 219, 0.7)",
                width: { xs: "60px", md: "70px" },
                height: { xs: "60px", md: "70px" },
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaEnvelope />
            </Box>
            <Box
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                textAlign: "center",
              }}
            >
              Low Open Rate
            </Box>
            <Box
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "gray",
                maxWidth: "400px",
                textAlign: "center",
                px: { xs: 2, md: 0 },
              }}
            >
              Many emails get ignored because of poor subject lines or bad
              timing. Our AI-powered subject line suggestions and send-time
              optimization ensure higher engagement.
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "30px", md: "40px" },
                color: "#FF3258",
                bgcolor: "rgba(249, 212, 219, 0.7)",
                width: { xs: "60px", md: "70px" },
                height: { xs: "60px", md: "70px" },
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BsFillLightningChargeFill />
            </Box>
            <Box
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                textAlign: "center",
              }}
            >
              Lack of Personalization
            </Box>
            <Box
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "gray",
                maxWidth: "400px",
                textAlign: "center",
                px: { xs: 2, md: 0 },
              }}
            >
              Generic emails fail to connect with audiences. We offer advanced
              segmentation and dynamic content to tailor messages for each
              subscriber.
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "30px", md: "40px" },
                color: "#FF3258",
                bgcolor: "rgba(249, 212, 219, 0.7)",
                width: { xs: "60px", md: "70px" },
                height: { xs: "60px", md: "70px" },
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faBrain} />
            </Box>
            <Box
              sx={{
                fontSize: { xs: "24px", md: "30px" },
                textAlign: "center",
              }}
            >
              Complex Automation Setup
            </Box>
            <Box
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "gray",
                maxWidth: "400px",
                textAlign: "center",
                px: { xs: 2, md: 0 },
              }}
            >
              Our intuitive automation builder makes it easy to create sequences
              like welcome emails, follow-ups, and re-engagement campaigns.
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Section2;
