import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import imgPreview from "../assets/main-screen-with-chat.png";
import imgDashboard from "../assets/main-screen-2.png";
import imgDashboard2 from "../assets/analyze-screen.png";

function Section4() {
  const imgs = [imgPreview, imgDashboard, imgDashboard2];
  const [currentImg, setCurrentImg] = useState(imgs[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImg((prevImg) => {
        const currentIndex = imgs.indexOf(prevImg);
        const nextIndex = (currentIndex + 1) % imgs.length;
        return imgs[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: { xs: 8, md: 15 },
          mb: 5,
        }}
      >
        <Typography sx={{ fontSize: { xs: "16px", md: "20px" }, color: "red" }}>
          How It Works
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "30px", md: "60px" },
            textAlign: "center",
            px: 2,
          }}
        >
          Just 3 steps to get started
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: { xs: "95%", md: "80%" },
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 4, md: 8 },
            mt: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}
          >
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "16px",
                p: { xs: 3, md: 4 },
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "4px",
                  height: "0%",
                  backgroundColor: "red",
                  animation: "borderAnimation1 15s linear infinite",
                },
                "@keyframes borderAnimation1": {
                  "0%": {
                    height: "0%",
                    backgroundColor: "red",
                  },
                  "33.33%": {
                    height: "100%",
                    backgroundColor: "red",
                  },
                  "33.34%": {
                    backgroundColor: "transparent",
                  },
                  "100%": {
                    height: "100%",
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                1. Upload Campaign Details
              </Typography>
              <Typography
                sx={{ color: "gray", fontSize: { xs: "16px", md: "20px" } }}
              >
                Provide your campaign objectives and target list to kickstart
                the process.
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "16px",
                p: { xs: 3, md: 4 },
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "4px",
                  height: "0%",
                  backgroundColor: "red",
                  animation: "borderAnimation2 15s linear infinite",
                },
                "@keyframes borderAnimation2": {
                  "0%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                  "33.33%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                  "33.34%": {
                    height: "0%",
                    backgroundColor: "red",
                  },
                  "66.66%": {
                    height: "100%",
                    backgroundColor: "red",
                  },
                  "66.67%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                  "100%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                2. 24/7 Lead Research by AI
              </Typography>
              <Typography
                sx={{ color: "gray", fontSize: { xs: "16px", md: "20px" } }}
              >
                AI-powered lead research agents that find all the right
                information and crafts hyperpersonalized emails that increase
                open rates.
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "16px",
                p: { xs: 3, md: 4 },
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "4px",
                  height: "0%",
                  backgroundColor: "red",
                  animation: "borderAnimation3 15s linear infinite",
                },
                "@keyframes borderAnimation3": {
                  "0%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                  "66.66%": {
                    height: "0%",
                    backgroundColor: "transparent",
                  },
                  "66.67%": {
                    height: "0%",
                    backgroundColor: "red",
                  },
                  "100%": {
                    height: "100%",
                    backgroundColor: "red",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                3. Launch & Track
              </Typography>
              <Typography
                sx={{ color: "gray", fontSize: { xs: "16px", md: "20px" } }}
              >
                Execute campaigns seamlessly while tracking performance in real
                time.
              </Typography>
            </Box>
          </Box>
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: { xs: "100%", md: "600px" },
              minHeight: { xs: "300px", md: "auto" },
            }}
          >
            <Box
              component="img"
              src={currentImg}
              alt="preview"
              sx={{
                position: "absolute",
                width: "100%",
                height: "auto",
                objectFit: "contain",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.2)",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Section4;
